/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

import { withRouter } from 'react-router-dom';

import {
    ProductListPage as SourceProductListPage
} from 'SourceComponent/ProductListPage/ProductListPage.component';

import './ProductListPage.override.style';

/** @namespace MasafiFrontend/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPage {}

export default withRouter(ProductListPageComponent);
