export const SHIPPING_STEP = 'SHIPPING_STEP';
export const BILLING_STEP = 'BILLING_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';
export const CCAVENUE_STEP = 'CCAVENUE_STEP';
export const CCAVENUE_EMAIL = 'CCAVENUE_EMAIL';

export const CHECKOUT_URL = '/checkout';

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';
export const PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS';

export const ONE_HOUR_IN_SECONDS = 3600;
export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms
export const CHECKOUT_COM_CODE = 'checkoutcom_card_payment';
export const PPTOKEN = 'TOKEN_PP';

export const PlatformSources = {
    standalone: 'app',
    web: 'web'
};
export const SUBSCRIPTION_DETAILS = 'subscription_details';
export const USE_EXISTING_CARD = 'use_existing_card';
export const SAVE_CARD_DETAILS = 'save_card_details';
